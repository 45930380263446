import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import NorwegianVippsJwtSnippet from '../../../snippets/jwt-payloads/norwegian-vipps';
export const _frontmatter = {
  "product": "verify",
  "category": "eIDs",
  "sort": 2,
  "title": "Norwegian Vipps",
  "subtitle": "Learn more about Norwegian Vipps token contents, how to create test users and how to gain access to production."
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`JWT/Token example`}</h2>
    <NorwegianVippsJwtSnippet mdxType="NorwegianVippsJwtSnippet" />
    <h2>{`Test users`}</h2>
    <p>{`In order to test Vipps Login you need to install the Vipps app in a special test version on your device. Follow the Vipps `}<a parentName="p" {...{
        "href": "https://developer.vippsmobilepay.com/docs/test-environment/#test-apps"
      }}>{`guide with instructions`}</a>{` on how to set up test apps.`}</p>
    <p>{`In order to test you also need a test phone number. Write to `}<a parentName="p" {...{
        "href": "mailto:support@criipto.com"
      }}>{`support@criipto.com`}</a>{` and put something like "Vipps Login test number" in the subject line.`}</p>
    <h2>{`Available data / scopes`}</h2>
    <p>{`Username is always made available. `}</p>
    <p>{`For applications configured to use a `}<inlineCode parentName="p">{`static`}</inlineCode>{` `}<inlineCode parentName="p">{`scope`}</inlineCode>{` strategy, address, email, phone and birthdate are always made available.
You can also configure your tenant so it requests SSN as well.`}</p>
    <p>{`For applications configured to use a `}<inlineCode parentName="p">{`dynamic`}</inlineCode>{` `}<inlineCode parentName="p">{`scope`}</inlineCode>{` strategy, the following `}<inlineCode parentName="p">{`scope`}</inlineCode>{` tokens can be supplied: `}<inlineCode parentName="p">{`address`}</inlineCode>{`, `}<inlineCode parentName="p">{`email`}</inlineCode>{`, `}<inlineCode parentName="p">{`phone`}</inlineCode>{`, `}<inlineCode parentName="p">{`birthdate`}</inlineCode>{` and `}<inlineCode parentName="p">{`ssn`}</inlineCode>{` (`}<inlineCode parentName="p">{`scope=openid phone email...`}</inlineCode>{`).`}</p>
    <h3>{`Example (partial) authorize request with scopes`}</h3>
    <pre><code parentName="pre" {...{}}>{`https://YOUR_SUBDOMAIN.criipto.id/oauth2/authorize?scope=openid phone ssn&...
`}</code></pre>
    <p>{`Alternatively, you can send them in the `}<inlineCode parentName="p">{`login_hint`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`https://YOUR_SUBDOMAIN.criipto.id/oauth2/authorize?...&login_hint=scope:phone scope:ssn&...
`}</code></pre>
    <p>{`which can be a useful if you are working with technology that does not let you control the `}<inlineCode parentName="p">{`scope`}</inlineCode>{` value.`}</p>
    <h2>{`Ordering Norwegian Vipps Login`}</h2>
    <p>{`To start accepting real users to log in with the Norwegian Vipps app, you must first request a set of secret keys - so-called `}<em parentName="p">{`client credentials`}</em>{` from Vipps AS.`}</p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p><strong parentName="p">{`Prerequisites for ordering`}</strong></p>
      <p>{`In order to apply for the Vipps Login client credentials for a company you must meet the basic requirements:`}</p>
      <ul>
        <li parentName="ul">{`Your company must be registered in the central Norwegian business registry, `}<em parentName="li">{`Brønnøysundregistrene`}</em></li>
        <li parentName="ul">{`The person that will sign the contract must be in possession of a Norwegian BankID`}</li>
        <li parentName="ul">{`You must have completed step 5 in the `}<a parentName="li" {...{
            "href": "/verify/guides/production"
          }}>{`Getting ready for production`}</a>{` guide. You will need the production domain when registering with Vipps.`}</li>
      </ul>
    </Highlight>
    <h3>{`Ordering the client credentials`}</h3>
    <p>{`Before you can order the credentials from Vipps you will need to have created a production domain in Criipto Verify as describe in the `}<a parentName="p" {...{
        "href": "/verify/guides/production"
      }}>{`Getting ready for production guide`}</a>{`). `}</p>
    <p>{`Once you have set up the production domain go to the `}<a parentName="p" {...{
        "href": "https://dashboard.criipto.com/providers/NO_VIPPS"
      }}>{`Identity sources`}</a>{` section and take note of the URL displayed. It must be supplied when ordering the client credentials.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "52.60416666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/ElEQVQoz22S2Y7iMBBF8/+fMU/zNp/RUvdTi4QmEAhkI2S3HWKywBnZTPcsmpKOXOW6LrtsO8l2h8hSdHFh+A/XS06fny1/x/k/PDXOZR+QG4Idmb8h2/okmw/O2y2Z79OcjnRxZEn9DVV4sH4bneiiyGJ8kSSIJMY5uyvOnku8WlGXJUoIeim5Smn9+XbjPk0s42jnRj3wmGc794mJr0ohigInX73T+BuaYIcxrTVVVdG2LVJKpFQIIRi0RiqF6nuba5rmOf7yhVLIpsbplWKZRkatud/vnM8F2+0Oz/M4HA5EUcRut8N1Xd5eX/F9nziO2e/3nE4njmFodcMwMIgOp45juixFFRceD+Aumecb0zQxjqPd5NpfCYLAFk7TlGVZbM5oPnXG+rbFEXXFIARj32PqzTpGdheEUM+WlSJOEs55TpKm9sS1aVEIlGlTKdu6WauFwFFlSZ0k1FnK437n0+ZpYh4N5jEUou1QQlpGfWMZJybzYMvytWboOpzmGHLZ+vbbPGzP8JhmbusjNy9k9I7cNzHzR8S4PtpYuwe0G6LfA+Zj/rugOeGQZ2jzafOMa1UiiwuqKFBlwdDUlGnKt+8/eHl54yZaVFXS19UXJjb3b/Qiy3CClcvBWxN6a/IgQGUpMk0sKk0QcUzoPnO9ySXJV/5PnR2zlJ/k6DwXdvwQ6gAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "NO Vipps setup",
          "title": "NO Vipps setup",
          "src": "/static/42c2a1cdd5be3a2a6502917f25b8acbb/0a47e/no-vipps.png",
          "srcSet": ["/static/42c2a1cdd5be3a2a6502917f25b8acbb/8514f/no-vipps.png 192w", "/static/42c2a1cdd5be3a2a6502917f25b8acbb/804b2/no-vipps.png 384w", "/static/42c2a1cdd5be3a2a6502917f25b8acbb/0a47e/no-vipps.png 600w"],
          "sizes": "(max-width: 600px) 100vw, 600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`To order the actual credentials please go to the Vipps order page located at `}<a parentName="p" {...{
        "href": "https://vippsbedrift.no/signup/logginnmedvipps"
      }}>{`https://vippsbedrift.no/signup/logginnmedvipps`}</a>{` and fill out the form as described.`}</p>
    <p>{`Note that the bears some resemblance to the form used to order Vipps for payment so please consider the notes below when filling out the form:`}</p>
    <ul>
      <li parentName="ul">{`The filed labelled `}<em parentName="li">{`"Navn på salgssted slik det vil vises for kunden i Vipps-appen"`}</em>{` is to be filled out with the name that should appear in the Vipps app when a user logs in`}</li>
      <li parentName="ul">{`In the section labelled `}<em parentName="li">{`"Organisasjonens kontaktinformasjon"`}</em>{` please enter the name and details of the person that will receive the secret keys. This person will also be granted access to the `}<a parentName="li" {...{
          "href": "https://portal.vipps.no/login"
        }}>{`Vipps administration portal`}</a></li>
    </ul>
    <h3>{`Next steps`}</h3>
    <p>{`After you have order the client credentials by filling out the online agreement it is proccessed by Vipps. And if you are approved, the designated contact person will receive the credentials. `}</p>
    <p>{`Once you have received the client credentials, they must be entered into the Criipto Verify management UI, as indicated in the above image, to configure your NO Vipps Login integration.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      